<template>
  <div :class="topBarClass">
    <v-app-bar dense flat>
      <v-row no-gutters justify="center">
        <div class="left"><slot></slot></div>
        <span class="subtitle-1">{{ title }}</span>
        <div class="right"><slot name="right"></slot></div>
      </v-row>
      </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  props: {
    title: {
      type: String,
      default: '默认'
    }
  },
  computed: {
    topBarClass() {
      return this.$vuetify.theme.dark ? `topDark` : `top`
    }
  }
}
</script>

<style scoped>
.top {
  height: auto;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background: rgb(245, 245, 245);
}
.topDark {
  height: auto;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background: rgb(39, 39, 39);
}
.left {
  position: absolute;
  left: 5px;
  top: 5px;
}
.right {
  position: absolute;
  right: 15px;
  top: 6px;
}
</style>