<template>
  <v-icon @click="back" large>mdi-chevron-left</v-icon>
</template>

<script>
export default {
  name: 'BackIcon',
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>